import FbIcon from "components/FbIcon";
import LinkedinIcon from "components/LinkedinIcon";
import Page from "components/Page";
import PostPreview from "components/PostPreview";
import TwitterIcon from "components/TwitterIcon";
import WhatsappIcon from "components/WhatsappIcon";
import { Link } from "components/Link";
import { kebabCase } from "lodash";
import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import defaultImage from "../images/studio-dsg-og.png";

interface Post {
  id: string;
  excerpt: string;
  html: string;
  fields: {
    slug: string;
  };
  frontmatter: {
    title: string;
    templateKey: string;
    date: string;
    articleDate: Date;
    description: string;
    image: { publicURL: string } | null;
    tags: string[];
  };
}

interface Props {
  pageContext: {
    locale: string;
    post: Post;
    suggestedPosts: { node: Post }[];
    tags: string[];
    url: string;
  };
}

export default function BlogPost({ pageContext }: Props) {
  const post = pageContext.post;
  const suggestedPosts = pageContext.suggestedPosts;

  return (
    <Page
      title={`${post.frontmatter.title} | Studio DSG`}
      description={post.excerpt}
      image={post.frontmatter.image && post.frontmatter.image.publicURL}
      localeCode="it"
      pageLocales={[
        { code: "it", url: pageContext.url.replace("https://studiodsg.it", "") }
      ]}
      isPostPage={true}
    >
      <Helmet>
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "${pageContext.url}"
            },
            "headline": "${post.frontmatter.title.replace(/"/g, "&quot;")}",
            "image": [
              "https://studiodsg.it/${defaultImage}"
            ],
            "datePublished": "${post.frontmatter.articleDate}",
            "author": {
              "@type": "Organization",
              "name": "Studio DSG"
            },
            "publisher": {
              "@type": "Organization",
              "name": "Studio DSG",
              "logo": {
                "@type": "ImageObject",
                "url": "https://studiodsg.it/${defaultImage}"
              }
            },
            "description": "${post.frontmatter.description.replace(
              /"/g,
              "&quot;"
            )}"
          }
        `}
        </script>
        <meta property="og:type" content="article" />
      </Helmet>
      <BlogPostTemplate>
        <Breadcrumb>
          <ol>
            <li>
              <Link to="/">Home page</Link>
            </li>
            <li>
              <Link to="/news/">News</Link>
            </li>
            <li>
              <span>{post.frontmatter.title}</span>
            </li>
          </ol>
        </Breadcrumb>
        <Title>{post.frontmatter.title}</Title>
        <Details>
          {post.frontmatter.date} - in{" "}
          <TagsList>
            {post.frontmatter.tags.map(t => (
              <Tag key={t}>
                <Link to={`/news/tags/${kebabCase(t)}/`}>{t}</Link>
              </Tag>
            ))}
          </TagsList>
        </Details>
        <Content dangerouslySetInnerHTML={{ __html: post.html }} />
        <ContactBanner>
          <p>Per ulteriori dubbi contattaci per una consulenza!</p>
          <p>
            Effettuiamo <Link to="/consulenza-online/">consulenze online</Link>,
            oppure ci puoi contattare telefonicamente allo 0445360277 o via mail
            all'indirizzo info@studiodsg.it.
          </p>
          <p>
            Lo Studio di commercialisti DSG - Dalla Stella & Gatti si trova a
            Thiene, in provincia di Vicenza.
          </p>
        </ContactBanner>
      </BlogPostTemplate>
      <ShareTitle>Share</ShareTitle>
      <SharePost>
        <a
          target="_blank"
          href={`https://www.facebook.com/sharer/sharer.php?u=${
            pageContext.url
          }`}
          title="Condividi su Facebook"
          className="popupFacebook"
        >
          <FbIcon />
        </a>
        <a
          target="_blank"
          href={`https://twitter.com/intent/tweet?text=${
            post.frontmatter.title
          }&amp;url=${pageContext.url}&via=twitter`}
          title="Condividi su Twitter"
        >
          <TwitterIcon />
        </a>
        <a
          target="_blank"
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${
            pageContext.url
          }&title=${post.frontmatter.title}`}
        >
          <LinkedinIcon />
        </a>

        <a
          target="_blank"
          href={`https://wa.me/?text=${post.frontmatter.title}
          ${pageContext.url}`}
        >
          <WhatsappIcon />
        </a>
      </SharePost>

      <NewsLink>
        <Link to="/news/">Ritorna agli articoli</Link>
      </NewsLink>

      <LatestPost>
        <Title>Ultimi articoli</Title>
        {suggestedPosts &&
          suggestedPosts.map(({ node: post }) => (
            <PostPreview key={post.id} post={post} />
          ))}
      </LatestPost>
    </Page>
  );
}

const BlogPostTemplate = styled.div`
  padding-top: 200px;
  width: 700px;
  margin: 0 auto;
  @media (max-width: 740px) {
    width: 100%;
    padding: 0 20px;
    padding-top: 200px;
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.mainColor};
    transition: 0.3s all;

    &:hover {
      color: ${({ theme }) => theme.mainColorDark};
    }
  }
  @media print {
    padding-top: 0;
  }
`;
const Breadcrumb = styled.nav`
  font-size: 14px;
  display: flex;
  justify-content: center;
  opacity: 0.7;

  ol {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    li {
      &:not(:last-child) {
        ::after {
          content: ">";
          padding: 0 5px;
        }
      }
      @media (max-width: 740px) {
        &:last-child {
          display: none;
        }
      }
    }
  }
  @media print {
    display: none;
  }
`;
const Title = styled.h1`
  font-weight: 700;
  font-size: 24px;
  font-family: ${({ theme }) => theme.fonts.title};
  padding: 15px 0;
  letter-spacing: 0.04em;
  line-height: 1.6em;
  color: ${({ theme }) => theme.mainColor};
  text-align: center;
  @media (max-width: 750px) {
    font-size: 20px;
  }
`;
const Details = styled.div`
  font-size: 14px;
  padding-bottom: 40px;
  text-align: center;
  &:first-letter {
    text-transform: uppercase;
  }
`;
const TagsList = styled.ul`
  display: inline-block;
  padding: 0;
  margin: 0;
`;
const Tag = styled.li`
  display: inline-block;
  :not(:first-child) {
    ::before {
      content: ", ";
      display: inline-block;
      padding-right: 4px;
    }
  }
`;

const Content = styled.div`
  color: #333;
  h1 {
    font-weight: 700;
    font-size: 24px;
    font-family: ${({ theme }) => theme.fonts.title};

    letter-spacing: 0.04em;
    line-height: 1.6em;
    margin-top: 20px;
    margin-bottom: 15px;
    padding-top: 10px;
    @media (max-width: 750px) {
      font-size: 18px;
    }
  }
  h2 {
    font-weight: 700;
    font-size: 20px;
    font-family: ${({ theme }) => theme.fonts.title};
    margin-top: 15px;
    margin-bottom: 15px;
    letter-spacing: 0.04em;
    line-height: 1.6em;

    margin-top: 15px;
    margin-bottom: 15px;
    @media (max-width: 750px) {
      font-size: 14px;
    }
  }
  p {
    font-size: 14px;
    line-height: 1.7em;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  li {
    font-size: 14px;
    line-height: 1.7em;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  hr {
    border: none;
    background-color: #ccc;
    height: 1px;
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  ul {
    padding-left: 40px;
    box-sizing: border-box;
  }
  blockquote {
    background-color: #f4f4f4;
    padding: 15px 30px;
    box-sizing: border-box;
    position: relative;
    &::before {
      content: "";
      display: block;
      background-color: #333;
      width: 2px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  code {
    font-size: 14px;
  }
  table {
    table-layout: auto;
    width: 100%;
    font-size: 14px;
    border-collapse: collapse;
    th {
      background-color: #eee;
    }
    td,
    th {
      border: 1px solid #999;
      line-height: 1.7em;
      padding: 0.5rem;
      text-align: left;
      vertical-align: top;
    }
  }
  iframe {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100% !important;
    height: 390px !important;
    @media (max-width: 600px) {
      height: 336px !important;
    }
    @media (max-width: 500px) {
      height: 280px !important;
    }
    @media (max-width: 400px) {
      height: 224px !important;
    }
  }
`;

const ContactBanner = styled.div`
  background-color: ${({ theme }) => theme.mainColorLight};
  padding: 10px 20px;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.main};
  color: ${({ theme }) => theme.mainColorDark};
  line-height: 1.5em;
  p {
    margin: 10px 0;
  }
  a {
    text-decoration: underline;
    font-weight: 700;
    transition: 0.3s all;
    color: ${({ theme }) => theme.mainColorDark};
    &:hover {
      color: ${({ theme }) => theme.mainColor};
    }
  }
  @media print {
    margin-top: 40px;
  }
`;

const ShareTitle = styled.div`
  margin-top: 60px;
  text-align: center;
  @media print {
    display: none;
  }
`;
const SharePost = styled.div`
  margin: 0 auto;
  display: block;
  text-align: center;
  padding-top: 10px;
  @media print {
    display: none;
  }
  ${FbIcon} {
    padding: 10px;
    fill: ${({ theme }) => theme.mainColor};
    &:hover {
      fill: ${({ theme }) => theme.mainColorDark};
    }
  }
  ${TwitterIcon} {
    padding: 10px;
    fill: ${({ theme }) => theme.mainColor};
    &:hover {
      fill: ${({ theme }) => theme.mainColorDark};
    }
  }
  ${LinkedinIcon} {
    padding: 10px;
    fill: ${({ theme }) => theme.mainColor};
    &:hover {
      fill: ${({ theme }) => theme.mainColorDark};
    }
  }
  ${WhatsappIcon} {
    padding: 10px;
    fill: ${({ theme }) => theme.mainColor};
    &:hover {
      fill: ${({ theme }) => theme.mainColorDark};
    }
  }
`;
const NewsLink = styled.div`
  text-align: center;
  margin-bottom: 60px;
  a {
    display: inline-block;
    text-decoration: none;
    transition: all 0.3s;
    margin-top: 20px;
    transition: 0.4s;
    color: ${({ theme }) => theme.textColor};
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.1em;
    &::before {
      content: "←";
      display: inline-block;
      font-size: 14px;
      padding-right: 5px;
      transition: 0.4s;
      font-family: system-ui;
    }
    &:hover {
      cursor: pointer;
      color: ${({ theme }) => theme.mainColor};
      &::before {
        margin-right: 10px;
        margin-left: -10px;
        color: ${({ theme }) => theme.mainColor};
      }
    }
  }
  @media print {
    display: none;
  }
`;

const LatestPost = styled.div`
  width: 700px;
  margin: 0 auto;
  @media (max-width: 740px) {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
  @media print {
    display: none;
  }
`;
